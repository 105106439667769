import { apiV8nocache } from "../axiousWithCache"

const state = {
  ladders: {
    LTC: null,
    LTS: null,
    both: null
  },
  editableLadder: null
}

const getters = {
  ladders(state) {
    return state.ladders
  },
  ladderTypes(state) {
    return state.ladders
      ? Object.keys(state.ladders).filter((key) => !!state.ladders[key])
      : null
  },
  editableLadder(state) {
    return state.editableLadder
  }
}

const mutations = {
  SET_LADDERS(state, ladders) {
    state.ladders = ladders
  },
  SET_LADDER_BY_TYPE(state, ladder) {
    if (ladder.type && Object.keys(state.ladders).includes(ladder.type)) {
      state.ladders[ladder.type] = ladder.data
    }
  },
  SET_EDITABLE_LADDER(state, editableLadder) {
    state.editableLadder = editableLadder
  }
}

const actions = {
  createLadder(context, ladders) {
    return new Promise((resolve, reject) => {
      apiV8nocache
        .post(`/admin/tardy/ladder`, ladders)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAllLadders(context) {
    return new Promise((resolve, reject) => {
      apiV8nocache
        .get("/admin/tardy/ladder")
        .then((response) => {
          const data = response.data
          if (data.data && data.data) {
            context.commit("SET_LADDERS", data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateLadders(context, ladders) {
    return new Promise((resolve, reject) => {
      apiV8nocache
        .post(`/admin/tardy/ladder`, ladders)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteladder(context, id) {
    return new Promise((resolve, reject) => {
      apiV8nocache
        .delete(`/admin/tardy/ladder/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
